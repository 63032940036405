




















































import { Component, Vue, Watch } from "vue-property-decorator";
import utils from "@/libs/utils";

@Component
export default class AppNotification extends Vue {
  pagerHeader: any = {
    title: "APP通知",
    desc: "APP通知",
    breadcrumb: ["系统管理", "APP通知"]
  };
  maintainForm: any = {
    submitLoading: false,
    paramsModel: {
      startTime: "",
      endTime: "",
      remark: ""
    }
  };
  maintainMessageSubmit() {
    (this.$refs.maintainForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.maintainForm.submitLoading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Notification/PublishMaintainNotification",
            {
              ...this.maintainForm.paramsModel
            }
          );
          if (res.data.success) {
            this.$notify.success("发送通知成功");
          }
        } catch (error) {}
        this.maintainForm.submitLoading = false;
      }
    });
  }
}
